
const Contents = props => {
  return <div className="contents">
    <section name="skills">
      <header>Technical Skill Set</header>
      <ul>
        <li>Experienced with many development tools including Atom, VS Code, Xcode,  Android Studio, Microsoft Visual Studio and the GNU Compiler Collection for  Windows, macOS and Linux environments.</li>
        <li>Skilled in the following development/markup languages: JavaScript, Java, Swift,  Python, TypeScript, Objective-C, C#, C/C++, PHP, Lua, Erlang, Scala, HTML/HTML5,  CSS/CSS3, Sass, XML, YAML, JSON.</li>
        <li>Proficient with the following platforms/frameworks and APIs: Android, iOS, macOS,  Linux, Windows, Amazon Web Services, Google Cloud Platform, EC2, Cloud  Storage, S3, Cloud Datastore, React, AngularJS, Node.js, Express, Docker,  Kubernetes, GraphQL, Gatsby, Spring, Hibernate, Ehcache, Redis, RabbitMQ,  MySQL, PostgreSQL, MongoDB, CouchDB, Solr, Unity, Laravel, WebGL/OpenGL, Direct3D.</li>
        <li>Experienced in designing software that interacts with both large relational  databases (MySQL/Microsoft SQL/Postgres) and NoSQL environments  (MongoDB/CouchDB).</li>
        <li>Real-world experience developing large asynchronous networked applications that  are scalable within distributed cloud environments, including Amazon Web Services  and Google Cloud platforms.</li>
        <li>Experienced in deploying e-Commerce solutions (In-App Purchases for iOS/ Android, payment gateways including Paypal/Braintree/Alipay/ICBC Bank of  China) for both mobile applications and web-based platforms.</li>
        <li>Solid practical experience developing mobile multi-media and business applications for both Android (Java/Kotlin) and iOS (Swift/Objective-C) platforms.</li>
        <li>Extensive background in the object-oriented development of multimedia, user  interface and 3D graphics systems using native OpenGL/Direct3D and Unity.</li>
        <li>Comfortable working within an Agile/SCRUM team environment and making  iterative changes to software features and requirements.</li>
        <li>Creatively driven and willing to learn new skills that are necessary to accomplish any required task.</li>
      </ul>
    </section>
    <section className="experience" name="experience">
      <header>Work Experience</header>
      <section name="swell">
        <div className="info"><span className="dates">July 2021 - 2022</span> | <span className="title">Senior Software Engineer</span> at <span className="company">Swell Energy</span></div>
        <ul>
          <li>Developed the login system/password reset API and associated React user interface with TypeScript/NestJS for the company’s customer-facing account management application.</li>
          <li>Implemented responsive React user interfaces from base UI/UX designs for the mobile version of the customer’s user portal application.</li>
          <li>Assisted in the maintenance and further development of legacy MeteorJS code for Swell’s employee-facing CRM software.</li>
          <li>Designed and built Node.js scripts to manage the migration/integration of data from various MongoDB databases for purposes of analytics tracking and logging.</li>
          <li>Integrated the AWS ElasticSearch API into the company's CRM platform to enhance customer account data search functionality.</li>
        </ul>
      </section>

      <section name="somo">
        <div className="info"><span className="dates">June 2020 - 2021</span> | <span className="title">Senior Software Engineer</span> at <span className="company">Somo Global</span></div>
        <ul>
          <li>Assisted in the development of new Node.js and Java back-end services for the Audi  vehicle reservation platform.</li>
          <li>Utilized React/Redux to build and enhance customer facing reporting and vehicle  management web-based applications for both retail and dealer clientele.</li>
          <li>Implemented GraphQL API’s and micro-services in both Java and Typescript to enable the interfacing of client applications to back-end services.</li>
          <li>Built React UI interfaces for the Somo Global corporate website/Audi reservation  management applications to design specifications utilizing the React and Gatsby  frameworks.</li>
        </ul>
      </section>

      <section name="next10">
        <div className="info"><span className="dates">June 2018 - 2019</span> | <span className="title">Technical Architect / Lead Engineer</span> at <span className="company">Next 10 Ventures</span></div>
        <ul>
          <li>Lead the creation of the company’s new Research &amp; Development team by architecting  key infrastructure and spear-heading the hiring of two additional junior engineers to build out innovative solutions to implement key company objectives.</li>
          <li>Acted as lead front-end developer of several applications, utilizing the React framework  and Javascript/HTML5 to quickly build out high-quality website components for both  prototype and production.</li>
          <li>Lead back-end development by designing and developing asynchronous Node.js and  Express micro-services to handle high-volume client requests, delegating  responsibilities of systems to distribute load via Google Cloud Kubernetes Engine.</li> 
          <li>Built services to pull Youtube API data and cache them on Google Datastore and Redis  for real-time consumption by clients.</li>
          <li>Implemented front-end and back-end communication protocols between micro services  by designing both custom RESTful HTTP APIs and GraphQL end-points.</li>
          <li>Architected the company’s Development Operations and content production pipeline  from the ground up by utilizing Gitlab’s CI/CD automation to deploy directly to  Kubernetes Engine hosted Docker containers.</li>
          <li>Wrote software integrations with the Slack API for automated deploy notifications, and  the Airtable API for pulling production content into MySQL storage for development  and live environments.</li>
          <li>Performed development research for the company’s strategic goals, prototyping  solutions in Node.js/React and building automation tools to analyze market trends on  social networks (Youtube).</li>
          <li>Mentored and assisted junior developers to help them grow their abilities and skill-set,  as well as integrate them into the company’s development workflow and code-style  guidelines.</li> 
          <li>Performed code reviews and quality checks for all engineers and approved commits to  code repositories before deployment to development/production environments.</li>
        </ul>
      </section>

      <section name="cnn">
        <div className="info"><span className="dates">March 2017 - June 2018</span> | <span className="title">Senior Software Engineer</span> at <span className="company">Turner / CNN Digital</span></div>
        <ul>
          <li>Migrated CNN and CNN Money back-end micro-services and editorial data from their on-premise location to being hosted on the AWS cloud environment in EC2 and S3.</li>
          <li>Modified critical Java SE/EE/Spring services to run within Docker containerized  environments using continuous integration and deployment on cloud-based virtual  servers (Bamboo/Terraform/AWS).</li>
          <li>Extended CNN and CNN Money RESTful API services to integrate them with the  Amazon S3 API to store editorial image and meta-data content in AWS.</li>
          <li>Built Python automation scripts to migrate multiple terabytes of CNN image and document content from on-premises storage to AWS Simple Storage Service.</li>
          <li>Managed the deployment of critical CNN and CNN Money website back-end services  to cloud environments without noticeable downtime for end-user customers.</li>
          <li>Mentored and assisted junior developers in understanding a complex microservice  based architecture used to deliver content to millions of users globally.</li>
          <li>Implemented Java unit tests for all micro-service feature changes to ensure consistent  functionality across new environments.</li>
        </ul>
      </section>

      <section name="merit">
        <div className="info"><span className="dates">March 2016 - 2017</span> | <span className="title">Senior Software Engineer</span> at <span className="company">Merit Technologies</span></div>
        <ul>
          <li>Acted as the lead engineer to develop a CRM application for both web (PHP/ Laravel/ MySQL) and mobile (Android/iOS) environments utilizing Node.js, Laravel, Swift and  Java.</li>
          <li>Designed and implemented a RESTful API interface in Laravel/PHP to communicate  JSON-encoded application data across multiple platforms (mobile Android/iOS and  web/HTML5).</li>
          <li>Maintained code quality by reviewing all work and changes to repositories before  application deployment.</li>
          <li>Improved team development processes by automating build and testing processes  through utilizing new technologies (Bitbucket/Pipelines) and improving internal  communication via Slack.</li> 
          <li>Advised and instructed junior engineers to solve programming challenges as  necessary.</li>
          <li>Maintained the code bases of various client applications written in PHP and .NET (C#/  ASP.NET) environments.</li>
        </ul>
      </section>

      <section name="blank-canvas">
        <div className="info"><span className="dates">October 2013 - March 2016</span> | <span className="title">Lead Engineer</span> at <span className="company">Blank Canvas</span></div>
        <ul>
          <li>Managed a core team of developers to create multimedia applications for Mobile  (Android/iOS native development in XCode and Android Studio) and web-based  (HTML5/Javascript) platforms.</li>
          <li>Assisted in defining and developing client solutions, building modern front-end  HTML5/Javascript applications utilizing AngularJS and React frameworks with  responsive design.</li>
          <li>Defined and enforced the company coding standard and security guidelines, ensuring  quality of the end product.</li>
          <li>Guided and mentored less experienced staff to assist in finding solutions to technical  problems related to native mobile application development as well as HTML5  standards.</li>
          <li>Maintained and monitored consistent performance of client digital assets (Virtual  Machines/Application Servers and Databases (Microsoft SQL/MySQL) on the Google  Cloud Platform and Amazon AWS).</li> 
        </ul>
      </section>

      <section name="outblaze">
        <div className="info"><span className="dates">June 2010 - October 2013</span> | <span className="title">Senior Software Engineer</span> at <span className="company">Outblaze</span></div>
        <ul>
          <li>Served as a senior engineer to continue supporting the massive multiplayer game  client and server technology acquired from Sparkplay Media.</li>
          <li>Performed a leading role in the migration of existing software infrastructure from  Sparkplay Media to Outblaze’s internal network.</li>
          <li>Provided support, mentoring, documentation and training to the company as the newly  acquired technology was being integrated into Outblaze’s existing software stack.</li>
          <li>Worked on and deployed many successful mobile applications for iOS and Android published under the Animoca brand-name, including both client and server side  development.</li>
          <li>Assisted and guided the Outblaze team in solving Objective-C, Java and Node.js  related engineering problems during development of multiple mobile applications.</li>
        </ul>
      </section>

      <section name="sparkplay">
        <div className="info"><span className="dates">May 2008 - June 2010</span> | <span className="title">Software Engineer</span> at <span className="company">Sparkplay Media</span></div>
        <ul>
          <li>Worked extensively as both a client and server engineer for the company's  massive multiplayer online game, Earth Eternal.</li>
          <li>Utilized C/C++ to develop the client technology on Windows, and the Java  Platform on Linux for the server infrastructure.</li>
          <li>Developed and maintained the product's client-side installer/patcher, Firefox/IE  web browser plugins, and 3D client platform.</li>
          <li>Maintained and implemented new features for the company's clustered network  server- side architecture.</li>
          <li>Implemented new features and development tools in Python/C/C++ to facilitate  the artist and design teams, as well as external technology licensees.</li>
        </ul>
      </section>
    </section>
  </div>
}

export default Contents
