import './App.css'

import Header from './components/header'
import Contents from './components/contents'
import Footer from './components/footer'

const App = () => {
  return (
    <div className="app">
      <Header />
      <Contents />
      <Footer />
    </div>
  )
}

export default App
