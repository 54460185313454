
const Header = props => {
  return <div className="header">
    <div className="details">
      <div className="name">Shane Parker</div>
      <div className="title">Senior Software Engineer</div>
      <div className="contact">hello@sparker.dev | <a href="tel:+1.678.760.7460">+1.678.760.7460</a></div>
    </div>
  </div>
}

export default Header
